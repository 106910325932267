import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { ContentInPolishLanguageAlert } from "../components/ContentInPolishLanguageAlert";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const ApplicationRules: React.FC<PageProps<DataProps>> = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout
      seoTitle={t("applicationRules.title")}
      seoDescription={t("applicationRules.title")}
      location={location}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <section className="page-header">
              <ContentInPolishLanguageAlert
                what={t("applicationRules.title")}
              />
              <h2>{t("applicationRules.title")}</h2>
            </section>
            <section className="privacy-policy-section">
              <div className="row">
                <div className="col-12">
                  <div className="privacy-policy-card card">
                    <div className="card-body">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("applicationRules.content"),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplicationRules;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
