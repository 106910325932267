import * as React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";

export function ContentInPolishLanguageAlert(props: { what: string }) {
  const { language } = useI18next("index");

  const messages = {
    en: {
      heading: "Dear user,",
      text: `We would like to inform you that ${props.what} is written in the Polish language. We recommend interpreting the content in Polish to avoid any misunderstandings. If you need to read ${props.what}, we encourage you to use a translator or text translation tools that can help you translate them into your preferred language.`,
      thankYou: "Thank you for your understanding.",
      sincerely: "Sincerely,",
      team: "Inspecto Team",
    },
    de: {
      heading: "Lieber Nutzer,",
      text: `Wir möchten Sie darüber informieren, dass ${props.what} in polnischer Sprache verfasst ist. Wir empfehlen, den Inhalt auf Polnisch zu interpretieren, um Missverständnisse zu vermeiden. Falls Sie ${props.what} lesen müssen, empfehlen wir Ihnen, einen Übersetzer oder ein Textübersetzungstool zu verwenden, um es in Ihre bevorzugte Sprache zu übersetzen.`,
      thankYou: "Vielen Dank für Ihr Verständnis.",
      sincerely: "Mit freundlichen Grüßen,",
      team: "Inspecto Team",
    },
  };

  const content = messages[language] || messages.en;

  return (
    language !== "pl" && (
      <div className="alert alert-warning mb-5" role="alert">
        <h4 className="alert-heading">
          <img
            src={`/images/languages/${language}.png`}
            alt={language}
            style={{
              width: 22,
              position: "relative",
              top: -2,
              marginRight: 10,
            }}
          />
          {content.heading}
        </h4>
        <p>{content.text}</p>
        <p>{content.thankYou}</p>
        <p>
          {content.sincerely}
          <br />
          {content.team}
        </p>
      </div>
    )
  );
}
